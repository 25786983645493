// extracted by mini-css-extract-plugin
export var post = "blog-post-module--post--X8t3J";
export var logo = "blog-post-module--logo--2XwFs";
export var title = "blog-post-module--title--2HgQg";
export var tags = "blog-post-module--tags--3UWY4";
export var content = "blog-post-module--content--2DbAl";
export var postNav = "blog-post-module--post-nav--3aXKv";
export var firstLink = "blog-post-module--first-link--1m5Bj";
export var secondLink = "blog-post-module--second-link--1Id0B";
export var postLink = "blog-post-module--post-link--10zt8";
export var reverse = "blog-post-module--reverse--115p4";