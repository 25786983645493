import React from "react"
// import { Link } from "gatsby"

//import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <div>
    <Seo title="404: Mega disaster" />
    <h1>Oh no!</h1>
  </div>
)

export default IndexPage
