// extracted by mini-css-extract-plugin
export var hero = "index-module--hero--eJTX7";
export var circles = "index-module--circles--2N5rb";
export var text = "index-module--text--1oPh_";
export var title = "index-module--title--3AdBQ";
export var image = "index-module--image--2D_u8";
export var links = "index-module--links--3t4Me";
export var blog = "index-module--blog--3wLX6";
export var projects = "index-module--projects--2k-Fy";
export var list = "index-module--list--1PmaS";
export var project = "index-module--project--1I5Wy";
export var maintenenceStatus = "index-module--maintenence-status--3Ml2W";
export var post = "index-module--post--amdyc";
export var meta = "index-module--meta--1A9Nv";
export var fadeIn = "index-module--fadeIn--2sRi4";