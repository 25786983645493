// extracted by mini-css-extract-plugin
export var navbar = "navbar-module--navbar--tfpmx";
export var transparent = "navbar-module--transparent--qgnCU";
export var logo = "navbar-module--logo--3PkIO";
export var menu = "navbar-module--menu--3LEiU";
export var breadcrumbs = "navbar-module--breadcrumbs--1G80g";
export var hidden = "navbar-module--hidden--oUine";
export var overlayMenu = "navbar-module--overlay-menu--3bw1V";
export var open = "navbar-module--open--3mdRf";
export var closed = "navbar-module--closed--1BxKv";
export var opening = "navbar-module--opening--2bHh7";
export var fadeIn = "navbar-module--fadeIn--wPaDW";
export var closing = "navbar-module--closing--25B9u";
export var fadeOut = "navbar-module--fadeOut--3cglO";
export var close = "navbar-module--close--WGIx9";
export var inner = "navbar-module--inner--1t0Mw";
export var items = "navbar-module--items--1EBmf";
export var sidebar = "navbar-module--sidebar--1sZ8W";
export var contact = "navbar-module--contact--V3ZkW";
export var email = "navbar-module--email--2UwAw";
export var links = "navbar-module--links--36IO1";